.Container{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: #171717 url('../../assets/img_bg_Home.png') center top fixed no-repeat;
	background-size: cover;
}
.Row{
	width: 100%;
	height: auto;
}
.Row:nth-child(1){
	padding-top: 3%;
	padding-left: 6%;
}

.Row:nth-child(2){
	display: flex;
	justify-content: space-between;
	padding: 5% 8% 0 11%;
	flex-wrap: wrap-reverse;
}
.LoginComponent{
	min-width: 365px;
	height: 375px;
	margin-top: 3.5%;
	margin-right: 10%;
}
.AppImageContainer{
	min-width: 465px;
	padding-top: 200px;
	width: 45%;
	height: auto;
	color: white;
}
.AppImageContainer img{ 
	max-width: 100%;
}
.LogoContainer{
	width: 153px;
	height: 80px;	
}
.LogoContainer img{ 
	max-width: 100%;
}

@media only screen and (max-width: 1140px) {
	.LoginComponent{
		margin: 0 auto 28px auto;
	}
	.AppImageContainer{
		margin: 0 auto;
	}
}

.TextCust {
	color: white
}