@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.App{
  width: 100vw;
  height: auto;
  min-height: 100vh;
}

.styles_Container__3elUa{
	width: 100%;
	height: 100%;
	padding-right: 20px;
	background-color: black;
}
.styles_Container__3elUa header {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
}
.styles_UserInfoContainer__24eq_{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 270px;
	height: 100%;
	padding-left: 13px;
	padding-right: 13px;
}
.styles_UserInfoContainer__24eq_ img {
	max-width: 70px;
	max-height: 40px;
	border-radius: 10%;
}
.styles_UserInfoContainer__24eq_ span {
	color: white;
	font-size: 14px;
}

.styles_Container__13et7{
	width: 100%;
	min-height: calc(100vh - 50px);
	padding: 25px;
	background-color: #F5F5F5;
}
.styles_CardContainer__3_bFr{
	width: 100%;
	height: 130px;
	display: flex;
	flex-direction: column;
	padding: 12.5px;
	border-radius: 2px;
	background: #2B2E33 url(/static/media/bg-card-saldo.1cac7d89.png) no-repeat right 0;
}
.styles_CardContainer__3_bFr span:nth-child(1){
	color: #ed831e;
	font-size: 16px;
	font-family: 'Lato', sans-serif;
}
.styles_BalanceText__3T-b1{
	color: white;
	font-size: 27px;
	font-family: 'Lato', sans-serif;
}
.styles_AnnouncementTextContainer__3fV-U{
	margin-top: 5px;
}
.styles_AnnouncementTextContainer__3fV-U p{
	color: #333;
	font-size: 24px;
	font-family: 'Calibri', sans-serif;
}

.styles_ImageContainer__3Xd6Q {
	text-align: center;
}

.styles_Image__2FS9v {
	width: 1040px;
	padding: 30px;
	align-self: center;
}

.styles_InfoBox__1D423 {
	padding: 20px;
	border: 2px solid #D1B467;
	margin: 20px;
}

h3 {
	text-align: center;
}

.styles_InfoText__15XpF {
	padding: 20px;
}

hr { 
	display: block; height: 2px;
    border: 0; border-top: 2px solid #D1B467;
		margin: 1em 0; padding: 0; 
}

.styles_Table__2a4MP {
	margin: 50px;
}

ul.styles_TableHeader__3g7i_ {
	font-weight: bold;
  padding: 10px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
	justify-content: space-between; 
	border-bottom: 2px solid black;
}	

ul.styles_TableRow__1FBkv {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
	justify-content: space-between;
}	

ul.styles_TableFooter__2wf6u {
	font-weight: bold;
  padding: 10px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
	justify-content: space-between; 
	border-top: 2px solid black;
}	

ul.styles_TableHeader__3g7i_ li, ul.styles_TableRow__1FBkv li, ul.styles_TableFooter__2wf6u li {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
	flex: 1 1;
	text-align: center;
}

h5 {
	color: red;
}
.styles_ContainerAdminHome__2tGsw {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: white;
}

*{
	box-sizing: border-box;
}

.styles_Container__1XH9Z{
	width: 100%;
	min-height: 100vh;
	background-color: #000000;
}
.styles_LogoContainer__3U7hP{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 180px;	
	background-color: #1f2123;
}
.styles_LogoContainer__3U7hP img{
	width: 220px;
	height: 220px;
}
.styles_MenuContainer__3W4Wd{
	width: 100%;
	margin-top: 10px;
}
.styles_Menu__37KVp{
	width: 100%;
}
.styles_MenuItem__YcODM{
	display: flex;
	align-items: flex-end;
	color: white;
	font-family: 'Raleway', sans-serif;
	height: 34.8px;
	padding: 7px 20px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 400;
	opacity: 0.62;
	cursor: pointer;
}
.styles_MenuItem__YcODM a{
	color: white;
	text-decoration: none;
	margin-right: 10px;
	font-size: 13px!important;
	text-align: center;
}
.styles_MenuItem__YcODM :nth-child(1){
	margin-right: 10px;
	font-size: 20px;
}

#styles_textSide__2RRVY {
	color: white;
	font-weight: bold;
	text-align: center;
}

.styles_Container__17zds{
	width: 100%;
	min-height: 100vh;
	display: flex;
}
.styles_Column__cDWZY{
	min-height: 100vh;
	width: auto;
}
.styles_Column__cDWZY:nth-child(1){
	width: 240px;
}
.styles_Column__cDWZY:nth-child(2){
	width: calc(100vw  - 240px);
}
.styles_SideBarContainer__2XN7U{
	width: 240px;
	min-height: 100vh;
	position: fixed;
}
.styles_HeaderContainer__1UwpP{
	width: calc(100vw - 240px);
	height: 50px;
	position: fixed;
	left:240px;
}
.styles_BodyContainer__2iPKD{
	width: calc(100vw - 240px);
	margin-top: 50px;
	min-height: calc(100vh - 50px);
	overflow-x: hidden;
	bottom: 0;
	background-color: #F5F5F5;
	display: flex;
	justify-content: center;
	align-items: center;
}
.styles_Container__2t4IV{
	width: 100%;
	height: 100%;
	font-family: 'Raleway';
}
.styles_Container__2t4IV input{
	width: 100%;
	height: 100%;
	background-color: #1F1F1F !important;
	border: 1px solid #444444;
	color: #D1B467 !important;
	font-size: 16px;
	padding-left: 8px;
}


.styles_Container__2_fm0{
	width: 100%;
	height: 100%;
	padding: 25px;
	border: 1px solid #333333;
	border-radius: 5px;
	background-color: #080808;
	font-family: 'Raleway', sans-serif;
}
.styles_Form__2uGLr{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.styles_Label__6GR28{
	color:#D1B467;
	font-size: 21px;
	margin-bottom: 5px;
}
.styles_InputContainer__3TJ-U{
	height: 50px;
	width: 100%;
	margin-bottom: 10px;
}
.styles_ConfirmButtonContainer__cnNvN{
	width: 100%;
	height: 53.6px;
	font-size: 16px;
	margin-top: 25px;
	margin-bottom: 15px;
}
.styles_ConfirmButtonContainer__cnNvN button{
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #D1B467;
	border: none;
	color: #FEFDFB;
	font-size: 16px;
	cursor: pointer;
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}
.styles_SpinnerContainer__Xoeio{
	position: absolute;
	margin: 0 auto;
	width: 50px;
	top: 10px;
	left: 140px;
}
.styles_ForgotPasswordContainer___g1yX{
	width: 100%;
	display: flex;
	justify-content: center;
}
.styles_ForgotPasswordContainer___g1yX a{
	font-size:16px;
	color: #D1B467;
	text-align: center;
}
.styles_MessageContainer__diHqw{
	color: white;
}
.styles_Container__3j_7m{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: #171717 url(/static/media/img_bg_Home.eb8e40e1.png) center top fixed no-repeat;
	background-size: cover;
}
.styles_Row__PSXZq{
	width: 100%;
	height: auto;
}
.styles_Row__PSXZq:nth-child(1){
	padding-top: 3%;
	padding-left: 6%;
}

.styles_Row__PSXZq:nth-child(2){
	display: flex;
	justify-content: space-between;
	padding: 5% 8% 0 11%;
	flex-wrap: wrap-reverse;
}
.styles_LoginComponent__19KX2{
	min-width: 365px;
	height: 375px;
	margin-top: 3.5%;
	margin-right: 10%;
}
.styles_AppImageContainer__1ByOS{
	min-width: 465px;
	padding-top: 200px;
	width: 45%;
	height: auto;
	color: white;
}
.styles_AppImageContainer__1ByOS img{ 
	max-width: 100%;
}
.styles_LogoContainer__2QC9L{
	width: 153px;
	height: 80px;	
}
.styles_LogoContainer__2QC9L img{ 
	max-width: 100%;
}

@media only screen and (max-width: 1140px) {
	.styles_LoginComponent__19KX2{
		margin: 0 auto 28px auto;
	}
	.styles_AppImageContainer__1ByOS{
		margin: 0 auto;
	}
}

.styles_TextCust__1miS- {
	color: white
}
.styles_Container__2sO6x{
	display: flex;
	width: 95%;
	height: 90%;
	border-radius: 5px;
	background-color: white;
	margin: 0 auto;
}
.styles_InformationsContainer__1KDZ3{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 30%;
	padding: 5% 0;
	background-color: black;
	color: white;
	text-align: right;
	font-family: 'Lato', sans-serif;
}
.styles_InformationsContainer__1KDZ3 > div{
	width: 80%;
	margin: 0 auto;
}
.styles_InformationTittle__23dAg h2{
	font-size: 24px;
	font-weight: 500;
	line-height: 1.1;
	text-transform: uppercase;
}
.styles_Line__vAYNB{
	margin-top:2px;
	width: 100%;
	height: 4px;
}
.styles_Line__vAYNB hr{
	border: 2px solid #A48749;
	width: 42px;
	float: right;
	margin: 0;
	padding: 0;
}
.styles_InformationText__1If6P{
	line-height: 23px;
}
.styles_InformationWhats__2QxfT{
    display: flex;
    flex-direction: column;
}
.styles_InformationWhats__2QxfT span:nth-child(1){
	font-size: 22px;
}
.styles_InformationWhats__2QxfT span:nth-child(2){
	font-size: 15px;
	color: #A48648;
}
.styles_InformationAddress__DHQjW{
	display: flex;
	flex-direction: column;
}
.styles_InformationAddress__DHQjW span:nth-child(1){
	font-size: 22px;
}
.styles_InformationAddress__DHQjW span:nth-child(2){
	font-size: 15px;
	color: #A48648;
}
.styles_FormContainer__3pFjp{
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 60%;
	padding: 5% 15% 20% 15%;
}
.styles_Row__h08YM{
	width: 100%;
}
.styles_Row__h08YM label{
	display: flex;
	flex-direction: column;
	font-size: 14px;
}
.styles_Row__h08YM input{
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.styles_Row__h08YM textarea{
	height: 54px;
	display: block;
	width: 100%;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
}
.styles_Row__h08YM input:focus{
	background-color: rgb(204, 204, 204);
	border: 1px solid lightblue;
}
.styles_Row__h08YM textarea:focus{
	background-color: rgb(204, 204, 204);
	border: 1px solid lightblue;
}
.styles_Button__2I5vD{
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: #c6a34e;
	width: 110px;
	height: 35px;
	border: 0;
	font-size: 14px;
	border-radius: 4px;
	margin-top: 5px;
}
.styles_ButtonContainer__1Wb29{
	width: 100%;
	display: flex;
	justify-content: flex-end;
}
.styles_Container__1APlj {
  width: 100%;
  min-height: calc(100vh - 50px);
  padding: 25px;
  background-color: #f5f5f5;
}
.styles_CardContainer__20wxv {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  padding: 12.5px;
  border-radius: 2px;
  background-color: #2b2e33;
}
.styles_FormDetails__2hMdZ {
  margin-top: 5px;
}
.styles_AnnouncementTextContainer__196vG p {
  color: #333;
  font-size: 24px;
  font-family: "Calibri", sans-serif;
}
.styles_TableHeader__rx-2w {
  text-align: left;
  color: white;
  font-size: 27px;
  font-family: "Lato", sans-serif;
}
table {
  width: 50%;
}
.styles_ClientDetailsTable__12i_e {
	padding-top: 5%;
}
#styles_Aright__2d11a {
  text-align: right;
}
#styles_TableHeaderDetails__3izoB {
	padding-top: 25px;
}
#styles_styledInput__3xhNJ {
  border: none;
  border-bottom: 1px solid 	#CFCFCF;
  background-color: #f5f5f5;
  color: black;
  padding: 7px 4px;
}
 #styles_labelBold__2rLLH {
	 font-weight: bold;
 }
.styles_Container__2_wdZ {
  width: 100%;
  min-height: calc(100vh - 50px);
  padding: 25px;
  background-color: #f5f5f5;
}
.styles_CardContainer__3Tpsj {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  padding: 12.5px;
  border-radius: 2px;
  background-color: #2b2e33;
}
.styles_FormDetails__ixsId {
  margin-top: 5px;
  width: 100%;
}
.styles_AnnouncementTextContainer__CqFvB p {
  color: #333;
  font-size: 24px;
  font-family: "Calibri", sans-serif;
}
.styles_ClientDetailsTable__1JCkq {
	padding-top: 5%;
}
#styles_Aright__19NPy {
  text-align: right;
}
#styles_TableHeaderDetails__3yncF {
	padding-top: 25px;
}
.styles_TableHeader__2kLEs {
  text-align: left;
  color: white;
  font-size: 27px;
  font-family: "Lato", sans-serif;
}
.styles_Container__3dxEv{
	width: 100%;
	min-height: calc(100vh - 50px);
	padding: 25px;
	background-color: #F5F5F5;
}
.styles_CardContainer__2kcEO{
	width: 100%;
	height: 130px;
	display: flex;
	flex-direction: column;
	padding: 12.5px;
	border-radius: 2px;
	background: #2B2E33 url(/static/media/bg-card-saldo.1cac7d89.png) no-repeat right 0;
}
.styles_AnnouncementTextContainer__2uINP{
	margin-top: 5px;
}
.styles_AnnouncementTextContainer__2uINP p{
	color: #333;
	font-size: 24px;
	font-family: 'Calibri', sans-serif;
}
.styles_TableHeader__YnPOQ {
	text-align: left;
	color: #82a897;
	font-size: 16px;
	font-family: 'Lato', sans-serif;
	font-weight: normal;
}	
#styles_BalanceText__hoVYU{
	color: white;
	font-size: 27px;
	font-family: 'Lato', sans-serif;
}
#styles_BalanceText0__VQpjm{
	color: white;
	font-size: 20px;
	font-family: 'Lato', sans-serif;
}
#styles_OldBalanceText__KonoG{
	color: white;
	font-size: 18px;
	font-family: 'Lato', sans-serif;
}
#styles_ImgBalanceText__3SBML {
	width: 6%;
}
#styles_ImgOldBalanceText__160LZ {
	width: 4.5%;
	}
table {
	width: 50%;
}
#styles_Aright__2buG2 {
	text-align: right;
}
*{
	box-sizing: border-box;
}

.styles_Container__3vrSj{
	width: 100%;
	min-height: 100vh;
	background-color: #000000;
}
.styles_LogoContainer__2vgOk{
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 180px;	
	background-color: #1f2123;
}
.styles_LogoContainer__2vgOk img{
	width: 220px;
	height: 220px;
}
.styles_MenuContainer__3KCZ2{
	width: 100%;
	margin-top: 10px;
}
.styles_Menu__2D567{
	width: 100%;
}
.styles_MenuItem__14qd3{
	display: flex;
	align-items: flex-end;
	color: white;
	font-family: 'Raleway', sans-serif;
	height: 34.8px;
	padding: 7px 20px;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 400;
	opacity: 0.62;
	cursor: pointer;
}
.styles_MenuItem__14qd3 a{
	color: white;
	text-decoration: none;
	margin-right: 10px;
	font-size: 13px!important;
	text-align: center;
}
.styles_MenuItem__14qd3 :nth-child(1){
	margin-right: 10px;
	font-size: 20px;
}

#styles_textSide__3VFTE {
	color: white;
	font-weight: bold;
	text-align: center;
}

.styles_Container__1Y2ol{
	width: 100%;
	min-height: calc(100vh - 50px);
	padding: 25px;
	background-color: #F5F5F5;
}
.styles_CardContainer__2h2dk{
	width: 100%;
	height: 130px;
	display: flex;
	flex-direction: column;
	padding: 12.5px;
	border-radius: 2px;
	background: #2B2E33 url(/static/media/bg-card-saldo.1cac7d89.png) no-repeat right 0;
}
.styles_CardContainer__2h2dk span:nth-child(1){
	color: #ed831e;
	font-size: 16px;
	font-family: 'Lato', sans-serif;
}
.styles_BalanceText__2V0im{
	color: white;
	font-size: 27px;
	font-family: 'Lato', sans-serif;
}
.styles_AnnouncementTextContainer__q8fI-{
	margin-top: 5px;
}
.styles_AnnouncementTextContainer__q8fI- p{
	color: #333;
	font-size: 24px;
	font-family: 'Calibri', sans-serif;
}

.styles_ImageContainer__3L35v {
	text-align: center;
}

.styles_Image__3J77y {
	width: 1040px;
	padding: 30px;
	align-self: center;
}

.styles_InfoBox__ldm8h {
	padding: 20px;
	border: 2px solid #D1B467;
	margin: 20px;
}

h3 {
	text-align: center;
}

.styles_InfoText__QIO83 {
	padding: 20px;
}

hr { 
	display: block; height: 2px;
    border: 0; border-top: 2px solid #D1B467;
		margin: 1em 0; padding: 0; 
}

.styles_Table__WNrVs {
	margin: 50px;
}

ul.styles_TableHeader__1wRrr {
	font-weight: bold;
  padding: 10px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
	justify-content: space-between; 
	border-bottom: 2px solid black;
}	

ul.styles_TableRow__3z5KY {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
	justify-content: space-between;
}	

ul.styles_TableFooter__vCwwc {
	font-weight: bold;
  padding: 10px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
	justify-content: space-between; 
	border-top: 2px solid black;
}	

ul.styles_TableHeader__1wRrr li, ul.styles_TableRow__3z5KY li, ul.styles_TableFooter__vCwwc li {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
	flex: 1 1;
	text-align: right;
}

h5 {
	color: red;
}
.term p, .term li, .term h2, .term ol {
	margin-top: 16px;
}

 .term ol, .term h2 {
	margin-bottom: 16px;
}

.term {
	padding: 50px;
	font-family: 'Calibri', sans-serif;
}

.term > .buttonContainer > .button{
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: #c6a34e;
	/* width: 110px; */
	height: 35px;
	border: 0;
	font-size: 14px;
	border-radius: 4px;
	margin-top: 5px;
	padding: 10px;
	text-transform: uppercase;
}
.term > .buttonContainer{
	width: 100%;
	display: flex;
	justify-content: flex-start;
}

.term .alphaList {
	list-style-type: lower-alpha;
	margin-left: 20px;
}

.term .romanList {
	list-style-type: lower-roman;
	margin-left: 20px;
}

.term a {
	color: #000000;
}

.styles_Container__3kYPd{
	width: 100%;
	min-height: 100vh;
	display: flex;
}
.styles_Column__3ykDq{
	min-height: 100vh;
	width: auto;
}
.styles_Column__3ykDq:nth-child(1){
	width: 240px;
}
.styles_Column__3ykDq:nth-child(2){
	width: calc(100vw  - 240px);
}
.styles_SideBarContainer__28jA8{
	width: 240px;
	min-height: 100vh;
	position: fixed;
}
.styles_HeaderContainer__1QVFa{
	width: calc(100vw - 240px);
	height: 50px;
	position: fixed;
	left:240px;
}
.styles_BodyContainer__3oktm{
	width: calc(100vw - 240px);
	margin-top: 50px;
	min-height: calc(100vh - 50px);
	overflow-x: hidden;
	bottom: 0;
	background-color: #F5F5F5;
	display: flex;
	justify-content: center;
	align-items: center;
}
.styles_Container__3GbxC{
	width: 100%;
	height: 100%;
	padding: 25px;
	border: 1px solid #333333;
	border-radius: 5px;
	background-color: #080808;
	font-family: 'Raleway', sans-serif;
}
.styles_Form__2EC2S{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.styles_Label__3HTKR{
	color:#D1B467;
	font-size: 21px;
	margin-bottom: 5px;
}
.styles_InputContainer___ku_I{
	height: 50px;
	width: 100%;
	margin-bottom: 10px;
}
.styles_ConfirmButtonContainer__3HE_5{
	width: 100%;
	height: 53.6px;
	font-size: 16px;
	margin-top: 25px;
	margin-bottom: 15px;
}
.styles_ConfirmButtonContainer__3HE_5 button{
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #D1B467;
	border: none;
	color: #FEFDFB;
	font-size: 16px;
	cursor: pointer;
	font-family: 'Raleway', sans-serif;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 1px;
}
.styles_SpinnerContainer__3Kb3j{
	position: absolute;
	margin: 0 auto;
	width: 50px;
	top: 10px;
	left: 140px;
}
.styles_ForgotPasswordContainer__1XOOQ{
	width: 100%;
	display: flex;
	justify-content: center;
}
.styles_ForgotPasswordContainer__1XOOQ a{
	font-size:16px;
	color: #D1B467;
	text-align: center;
}
.styles_MessageContainer__sjeUQ{
	color: white;
}
.styles_Container__VbTDr{
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background: #171717 url(/static/media/img_bg_Home.eb8e40e1.png) center top fixed no-repeat;
	background-size: cover;
}
.styles_Row__1ORu3{
	width: 100%;
	height: auto;
}
.styles_Row__1ORu3:nth-child(1){
	padding-top: 3%;
	padding-left: 6%;
}

.styles_Row__1ORu3:nth-child(2){
	display: flex;
	justify-content: space-between;
	padding: 5% 8% 0 11%;
	flex-wrap: wrap-reverse;
}
.styles_LoginComponent__1LZ48{
	min-width: 365px;
	height: 375px;
	margin-top: 3.5%;
	margin-right: 10%;
}
.styles_AppImageContainer__35yop{
	min-width: 465px;
	padding-top: 200px;
	width: 45%;
	height: auto;
	color: white;
}
.styles_AppImageContainer__35yop img{ 
	max-width: 100%;
}
.styles_LogoContainer__3QayF{
	width: 153px;
	height: 80px;	
}
.styles_LogoContainer__3QayF img{ 
	max-width: 100%;
}

@media only screen and (max-width: 1140px) {
	.styles_LoginComponent__1LZ48{
		margin: 0 auto 28px auto;
	}
	.styles_AppImageContainer__35yop{
		margin: 0 auto;
	}
}

.styles_TextCust__36CIZ {
	color: white
}
body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

