* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

.App{
  width: 100vw;
  height: auto;
  min-height: 100vh;
}